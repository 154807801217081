<template>
	<div :ref="'wrapper_'+this.id"
		 :class="[this.wrapper, {
			'variants-show': this.variants_show,
		 }]">
		<label v-if="this.label" :for="this.id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<div class="d-inline-flex align-items-stretch input-group" :class="this.group">
			<div v-if="this.prepend" v-html="this.prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<input :id="this.id"
				   :type="this.type"
				   class="form-control"
				   :class="[this.class, {
						'is-valid': this.valid,
						'is-invalid': this.invalid,
						'rounded-bottom mb-0': this.variants_show,
				   }]"
				   ref="input"
				   :name="this.name"
				   v-model="this.value"
				   v-maska="this.mask"
				   :placeholder="this.placeholder"
				   :inputmode="this.mode"
				   :disabled="this.disabled"
				   :readonly="this.readonly"
				   :required="this.required"
				   @input.stop.prevent="(event) => this.emitValue(event.target.value, 'inputTyped')"
				   @change="(event) => this.emitValue(event.target.value, 'setValue')" 
				   @input="(event) => this.emitValue(event, 'setInput')"/>

			<ul v-if="!this.isEmpty(this.variants) && this.variants_show"
				class="d-flex w-100 flex-column flex-fill mx-0 mt-0 pt-0 list-group"
				style="overflow-y: auto; max-height: 25vh;">
				<li v-for="(variant, index) of this.variants"
					:key="index"
					class="list-group-item">
					<span class="d-inline-flex w-100 fs-5 list-item pointer-event"
						  @click="() => { !this.isEmpty(variant.value) ? this.emitValue(variant.value, 'setValue', true) : false }"
						  v-html="variant.label" />
				</li>
			</ul>

			<div v-if="this.append" v-html="this.append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
			<div v-if="this.remove" class="input-group-text align-self-start text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3">
				<button type="button"
						aria-label="Удалить"
						class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
						style="width: 44px; height: 44px;"
						@click="this.emitRemove">
					<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="14" height="14">
				</button>
			</div>
			<div v-if="this.save" class="input-group-text align-self-start text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3">
				<button type="button"
						aria-label="Удалить"
						class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
						style="width: 44px; height: 44px;"
						:disabled="this.is_save_disabled"
						@click="this.emitSave">
					<img :src="require('@/assets/icons/save-icon.svg')" class="d-block" alt="Сохранить" width="14" height="14">
				</button>
			</div>
			<div v-if="this.valid && typeof this.valid != 'boolean'" class="valid-feedback" v-html="this.valid" />
			<div v-else-if="this.invalid && typeof this.invalid != 'boolean'" class="invalid-feedback" v-html="this.invalid" />
		</div>
		<div v-if="this.help" class="form-text" :class="this.helpClass" v-html="this.help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputType: {type: String},
		inputName: {type: String},
		inputValue: {type: String},
		inputMode: {type: String},
		inputMask: {type: [String, Array]},
		inputVariants: {type: Array},
		inputClass: {type: String},
		inputGroupClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputRemove: {type: [String, Boolean], default: false},
		inputSave: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : '',
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			mode: (typeof this.inputMode !== "undefined") ? this.inputMode : '',
			group: (typeof this.inputGroupClass !== "undefined") ? this.inputGroupClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			//help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			//prepend: (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false,
			//append: (typeof this.inputAppend !== "undefined") ? this.inputAppend : false,
			mask: (typeof this.inputMask !== "undefined") ? this.inputMask : false,
			remove: (typeof this.inputRemove !== "undefined") ? this.inputRemove : false,
			save: (typeof this.inputSave !== "undefined") ? this.inputSave : false,
			is_save_disabled: false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
			variants_show: false,
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		validateInput() {
			let input = this.$refs.input;
			if (this.invalid)
				input.setCustomValidity(this.invalid);
			else
				input.setCustomValidity("");

		},
		emitValue(value, event_name, hide_variant) {
			
			if (this.isEmpty(value))
				this.is_save_disabled = true;
			else
				this.is_save_disabled = false;

			if (typeof event_name == "undefined")
				event_name = 'setValue';

			if (typeof hide_variant == "undefined")
				hide_variant = true;

			if (typeof this.mask == "undefined" && !this.isEmpty(this.mask))
				value = this.$refs.input.dataset['mask-raw-value'];

			CommonService.log('debug', 'emitValue()', {value, event_name});

			this.$emit(event_name, value);

			if (event_name == 'setValue' && hide_variant) {
				setTimeout(() => {
					this.variants_show = false;
				}, 300);
        this.$emit('onItemSelected', value);
			} else if (event_name == 'inputTyped' && hide_variant) {
				CommonService.debounce(this.emitValue(value, 'setValue', false), 1000);
			}

		},
		emitRemove() {
			CommonService.log('debug', 'emitRemove()', {value: this.value, id: this.id});

			if (this.$emit('removeValue', this.value))
				this.$refs['wrapper_'+this.id].remove();

		},
		emitSave() {

			this.is_save_disabled = true;

			CommonService.log('debug', 'emitSave()', {value: this.$refs.input.value, id: this.id});

			if (this.$emit('saveValue', this.$refs.input.value)) {
				let is_disabled = this.$refs.input.disabled;
				this.$refs.input.disabled = true;

				let is_readonly = this.$refs.input.readOnly;
				this.$refs.input.readOnly = true;

				setTimeout(() => {
					this.$refs.input.disabled = is_disabled;
					this.$refs.input.readOnly = is_readonly;
					this.is_save_disabled = false;
				}, 1500);
			}

		},
		closeIfClickedOutside(event) {
			if (document.querySelector('.variants-show')) {
				if (!document.querySelector('.variants-show').contains(event.target)) {
					this.variants_show = false;
					document.removeEventListener('click', this.closeIfClickedOutside);
				}
			}
		},
	},
	mounted() {
		window.addEventListener('click', this.closeIfClickedOutside);
		this.validateInput();
		/*this.$nextTick(() => {
			if (this.mask && this.mask !== 'false') {
				alert(this.mask);
			}
		});*/
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		},
		'variants': function(val, oldVal) {
			this.variants_show = true;
		},
		/*'mask': function(val, oldVal) {
			this.$refs.input.dataset.mask = this.mask;
			this.$refs.input.value = this.value;
		}*/
	},
	computed: {
		type() {
			return (typeof this.$props.inputType !== "undefined") ? this.$props.inputType : 'text';
		},
		value: {
			get() {
				return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : '';
			},
			set(value) {
				if (typeof (value) === "string")
					value = value.trim();
				else
					value = Number(value);
			}
		},
		disabled() {
			return this.$props.inputDisabled;
		},
		variants() {
			return (typeof this.$props.inputVariants !== "undefined") ? this.$props.inputVariants : '';
		},
		required() {
			return this.$props.inputRequired;
		},
		readonly() {
			return this.$props.inputReadonly;
		},
		help() {
			return (typeof this.$props.inputHelpText !== "undefined") ? this.$props.inputHelpText : '';
		},
		prepend() {
			return (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false;
		},
		append() {
			return (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false;
		},
	},
}
</script>